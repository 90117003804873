import TextInput from "../input-components/TextInput";
import SelectInput, { OptionValue } from "../input-components/SelectInput";
import { useEffect, useMemo, useState } from "react";
import { RsvpData } from "../RsvpState";
import { set } from "lodash";

function SubmitRsvpData({
  data,
  onSubmit,
  error,
}: {
  data: RsvpData,
  onSubmit: (data: RsvpData) => void,
  error?: string
}) {
  const [localState, setLocalState] = useState({ ...data });
  const numberOfFolks = useMemo(() => {
    const c: OptionValue[] = [];
    for (let i = 0; i < data.numberInvited; i++) {
      c.push({
        display: i + 1,
        value: i + 1
      });
    }
    return c;
  }, [data.numberInvited]);

  useEffect(() => {
    setLocalState(data);
  }, [data])

  let nameString = data.names.length ? data.names.reduce((pv, cv) => `${pv}` ? `${pv} & ${cv}` : pv) : 'Unknown';
  if (data.numberInvited > 2) {
    nameString = nameString.replace(" & ", ", ");
    nameString = `${nameString} & Family`;
  }

  return (
    <>
      <h2 className="center">Hey {nameString}!</h2>
      <div className="grid Rsvp-step">
        <p className="span-12">
          If you would like to use our travel agent, please RSVP and reach out to Bridget
          at <a href="mailto:bmacqueen@maritimetravel.ca">bmacqueen@maritimetravel.ca</a>.
        </p>
        <p className="span-12">
          <b>If you don't intend to use our travel agent or won't be coming, please still RVSP!
            Don't be shy - helping us figure out our numbers is a cool thing to do 👍.</b>
        </p>
        <p className="span-12">
          If you ever need to come back and make changes, just enter your RSVP code and update your submission.
          <b> We will also have a big party (tornaboda) when we get home, so if you can't make it, no worries!
            We will keep you posted 🫶.</b>
        </p>
        <form className="span-12" onSubmit={(evt) => { evt.preventDefault(); onSubmit(localState) }}>
          <SelectInput id="coming" placeholder="Are you coming?" labelValue="Are you coming?"
            value={localState.coming ? 1 : 0}
            values={[
              { value: 1, display: 'Yes' },
              { value: 0, display: 'No' },
            ]} onChange={(val) => {
              setLocalState({
                ...data,
                coming: val === '1' ? true : false
              })
            }}
          />
          {
            localState.coming &&
            <>
              <SelectInput id="number-of-people"
                value={localState.numberComing}
                placeholder="How many people?"
                labelValue="How many are coming?"
                values={numberOfFolks} onChange={(num) => {
                  setLocalState({
                    ...localState,
                    numberComing: Number(num)
                  })
                }}
              />
              <SelectInput id="travel-agent" placeholder="Use our travel agent?" labelValue="Use our travel agent?"
                value={localState.travelAgent ? 1 : 0}
                values={[
                  { value: 1, display: 'Yes' },
                  { value: 0, display: 'No' },
                ]} onChange={(val) => {
                  setLocalState({
                    ...localState,
                    travelAgent: val === '1' ? true : false
                  })
                }}
              />
              <TextInput
                id="email"
                inputType="email"
                placeholder="email@imacomputer.com"
                labelValue="Email for contact"
                required={localState.travelAgent}
                value={localState.email}
                maxLength={100}
                onChange={(val) => setLocalState({ ...localState, email: val })} />
              <TextInput
                id="dietary-restrictions"
                inputType="text"
                placeholder="Name and restriction"
                labelValue="Any dietary restrictions?"
                value={localState.dietaryRestrictions}
                maxLength={300}
                onChange={(val) => setLocalState({ ...localState, dietaryRestrictions: val })} />

              <TextInput
                id="song-recommendation"
                inputType="text"
                placeholder="Not baby shark"
                labelValue="A song recommendation?"
                value={localState.song}
                maxLength={150}
                onChange={(val) => setLocalState({ ...localState, song: val })} />
            </>
          }
          <hr />
          {error && <p className="span-12 Rsvp-error">{error}</p>}
          <div className="Rsvp-submit-row">
            <button className="App-button" type="submit">Submit RSVP</button>
          </div>
        </form>
      </div>
    </>
  );
}

export default SubmitRsvpData;
