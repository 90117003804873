import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';


function HowWeMet() {
  return (
    <>
      <h2 className='center'>How We Met (and how we ended up here)</h2>
      <div className='grid'>
        <div className='span-12'>
          <VerticalTimeline lineColor="rgba(255,255,255,0.72)">
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="August 31st, 2019"
              contentStyle={{ background: '#353234', color: 'rgba(255,255,255,0.86)' }}
              contentArrowStyle={{ borderRight: '7px solid  #353234' }}
              iconStyle={{ background: '#353234', color: 'rgba(255,255,255,0.72)' }}
            >
              <h3 className="vertical-timeline-element-title">First Time We Met</h3>
              <h4 className="vertical-timeline-element-subtitle">Landon &amp; Kim's, Airdrie, AB</h4>
              <p>
                Oh boy, what a night. It was Landon and Kim's tornaboda, and Paris, one of Kim's friends,
                was bringing her sister, Jasmyne, to the party. Jas (aka Jazz, aka DJ Jazzy Jazz) and Ken spent the night drinking too much tequila and
                playing beer pong.
              </p>
              <p>
                Jas was immediately impressed with his John Lennon buttonup shirt and knowledge of classic rock,
                although she doesn't remember most of their conversation from that evening. Ken was surprised
                someone wanted to talk to him.
              </p>
              <p>
                The night ended with Ken nowhere to be found after giving Jas the wrong number. Luckily some friends
                rectified that issue. Later that night Jas went swimming.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="September 21st 2019"
              contentStyle={{ background: '#4c4048', color: 'rgba(255,255,255,0.86)' }}
              contentArrowStyle={{ borderRight: '7px solid  #353234' }}
              iconStyle={{ background: '#4c4048', color: 'rgba(255,255,255,0.72)' }}
            >
              <h3 className="vertical-timeline-element-title">First Date</h3>
              <h4 className="vertical-timeline-element-subtitle">Dalhousie Kinjo Sushi, Calgary, AB</h4>
              <p>
                After almost blowing it with his terrible texting skills, Ken finally asked Jas out.
                Jas almost didn't go as she was enjoying the luxury of a hot tub and game night, but thankfully
                the stars aligned. And this wasn't your regular old lousy coffee date. This was sushi 🍣!
              </p>
              <p>
                They met at 7pm and talked for hours on end. Somehow Ken didn't understand that this was in fact a
                good thing, but the date was <i>successful</i>.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="September 30th, 2019"
              contentStyle={{ background: '#353234', color: 'rgba(255,255,255,0.86)' }}
              contentArrowStyle={{ borderRight: '7px solid  #353234' }}
              iconStyle={{ background: '#353234', color: 'rgba(255,255,255,0.72)' }}
            >
              <h3 className="vertical-timeline-element-title">Dinner at Brewhouse!</h3>
              <h4 className="vertical-timeline-element-subtitle">Brewhouse, Airdrie, AB</h4>
              <p>
                Impromptu dinner since Jas was in Airdrie. Not very memorable, but still <i>successful</i>.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="October 6th 2019"
              contentStyle={{ background: '#4c4048', color: 'rgba(255,255,255,0.86)' }}
              contentArrowStyle={{ borderRight: '7px solid  #353234' }}
              iconStyle={{ background: '#4c4048', color: 'rgba(255,255,255,0.72)' }}
            >
              <h3 className="vertical-timeline-element-title">Fun at the Rec Room</h3>
              <h4 className="vertical-timeline-element-subtitle">The Rec Room, Calgary, AB</h4>
              <p>
                Jas and Ken met for a night of gaming at The Rec Room. Here Ken proved his supriority in air hockey,
                while Jas took the Mario Kart crown. They also honed their team work skills killing zombies.
                After a <i>successful</i> date, neither wanted the good times to end, so Ken invited Jas over to his
                house to meet his puppy Aeris and take her for a walk.
              </p>
              <p>
                Immediately Aeris fell in love with Jas, and Jas with Aeris. Jas and Ken hung out until the wee hours playing
                VR and chatting.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="October 19th, 2019"
              contentStyle={{ background: '#353234', color: 'rgba(255,255,255,0.86)' }}
              contentArrowStyle={{ borderRight: '7px solid  #353234' }}
              iconStyle={{ background: '#353234', color: 'rgba(255,255,255,0.72)' }}
            >
              <h3 className="vertical-timeline-element-title">A Locked Room!</h3>
              <h4 className="vertical-timeline-element-subtitle">Some locked room place, Calgary, AB</h4>
              <p>
                So far things had been going well, and Jas and Ken did a locked room as <i>boyfriend and girlfriend </i>
                with a bunch of Ken's friends. For those curious, the group was <i>successful</i> and escaped the room.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="November 13th, 2019"
              contentStyle={{ background: '#4c4048', color: 'rgba(255,255,255,0.86)' }}
              contentArrowStyle={{ borderRight: '7px solid  #353234' }}
              iconStyle={{ background: '#4c4048', color: 'rgba(255,255,255,0.72)' }}>
              <h3 className="vertical-timeline-element-title">Facebook Official</h3>
              <h4 className="vertical-timeline-element-subtitle">The interwebs</h4>
              <p>
                Jas and Ken told their best friend Mark about their relationship.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="December 1st 2019"
              contentStyle={{ background: '#353234', color: 'rgba(255,255,255,0.86)' }}
              contentArrowStyle={{ borderRight: '7px solid  #353234' }}
              iconStyle={{ background: '#353234', color: 'rgba(255,255,255,0.72)' }}
            >
              <h3 className="vertical-timeline-element-title">Paint Night!</h3>
              <h4 className="vertical-timeline-element-subtitle">Airdrie, AB</h4>
              <p>
                Jas and Ken did some painting together - Bob Ross style. They turned out pretty good.
                The evidence of this evening is now framed on their stairway wall. Murman says that
                Jas painted the better mountains, but her trees kind of sucked.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="December 14th, 2019"
              contentStyle={{ background: '#4c4048', color: 'rgba(255,255,255,0.86)' }}
              contentArrowStyle={{ borderRight: '7px solid  #353234' }}
              iconStyle={{ background: '#4c4048', color: 'rgba(255,255,255,0.72)' }}>
              <h3 className="vertical-timeline-element-title">Ken Meets Jas' Parents</h3>
              <h4 className="vertical-timeline-element-subtitle">Airdrie, AB</h4>
              <p>
                After watching a local theatre production of Matilda, Jas and Ken popped by Jas'
                parents house to say hello. Jas had to pick some stuff up and figured this would be a quick
                hello, but Jas' dad Will had other plans and offered Ken a glass of wine. To quote Ken,
                "I can't turn that down."
              </p>
              <p>
                Jas' Mom, Julie, rated Ken an A+ and later upgraded that to "AAA". Frankly, this scale is confusing.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="December 15th, 2019"
              contentStyle={{ background: '#353234', color: 'rgba(255,255,255,0.86)' }}
              contentArrowStyle={{ borderRight: '7px solid  #353234' }}
              iconStyle={{ background: '#353234', color: 'rgba(255,255,255,0.72)' }}>
              <h3 className="vertical-timeline-element-title">Jas Meets Ken's Parents</h3>
              <h4 className="vertical-timeline-element-subtitle">Airdrie, AB</h4>
              <p>
                Ken invited Jas over to meet his parents: Louise and Murman (aka Murray, aka Murph, aka DJ Mur-Music).
                They probably had a roast, as one does. Dinner was good and Ken remembers his parents doing a reasonable
                job of not chasing his new girlfriend away.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="January 29th, 2020"
              contentStyle={{ background: '#4c4048', color: 'rgba(255,255,255,0.86)' }}
              contentArrowStyle={{ borderRight: '7px solid  #353234' }}
              iconStyle={{ background: '#4c4048', color: 'rgba(255,255,255,0.72)' }}>
              <h3 className="vertical-timeline-element-title">Moving In?</h3>
              <h4 className="vertical-timeline-element-subtitle">Airdrie, AB</h4>
              <p>
                Jas and Ken talk about officially living togther. Up to this point Jas had nested, set up
                a Christmas tree, and was basically Aeris' human mom. Ken, being pragmatic, suggested that Jas should
                give it at least another month to make sure she still liked him. Spoilers: <i>successful</i>.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="End of March, 2020"
              contentStyle={{ background: '#353234', color: 'rgba(255,255,255,0.86)' }}
              contentArrowStyle={{ borderRight: '7px solid  #353234' }}
              iconStyle={{ background: '#353234', color: 'rgba(255,255,255,0.72)' }}>
              <h3 className="vertical-timeline-element-title">Moved In</h3>
              <h4 className="vertical-timeline-element-subtitle">Airdrie, AB</h4>
              <p>
                Jas is officially home, together with Ken. Feels good. Thankfully, this was done before
                Covid kicked it into gear and Jas and Ken got to spend more time together than anyone could
                have imagined.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="September 21st, 2020"
              contentStyle={{ background: '#4c4048', color: 'rgba(255,255,255,0.86)' }}
              contentArrowStyle={{ borderRight: '7px solid  #353234' }}
              iconStyle={{ background: '#4c4048', color: 'rgba(255,255,255,0.72)' }}>
              <h3 className="vertical-timeline-element-title">First Anniversary</h3>
              <h4 className="vertical-timeline-element-subtitle">Country Hills Kinji Sushi, Calgary, AB</h4>
              <p>
                <s>Ken and Jas finally say "I love you".</s> Just kidding that happened a long while ago (2019?) but they
                couldn't find the date. Jas brought over some very lovely coffee mugs and kind of just snuck
                the words out there. Ken remembers saying it back immediately, but that is up for debate.
              </p>
              <p>
                This was their first anniversary, and to celebrate they returned to Kinjo. Sadly, the Dalhousie
                location was closed, so the Country Hills location had to make do. The rumour on the street was
                that Ken was going to propose, so he instructed Jas to "keep her expectations low."
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="December 25th, 2020"
              contentStyle={{ background: '#353234', color: 'rgba(255,255,255,0.86)' }}
              contentArrowStyle={{ borderRight: '7px solid  #353234' }}
              iconStyle={{ background: '#353234', color: 'rgba(255,255,255,0.72)' }}>
              <h3 className="vertical-timeline-element-title">Christmas Day</h3>
              <h4 className="vertical-timeline-element-subtitle">Airdrie, AB</h4>
              <p>
                Expectations were kept appropriately low.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="September 21st, 2021"
              contentStyle={{ background: '#4c4048', color: 'rgba(255,255,255,0.86)' }}
              contentArrowStyle={{ borderRight: '7px solid  #353234' }}
              iconStyle={{ background: '#4c4048', color: 'rgba(255,255,255,0.72)' }}>
              <h3 className="vertical-timeline-element-title">Second Anniversary</h3>
              <h4 className="vertical-timeline-element-subtitle">Country Hills Kinji Sushi, Calgary, AB</h4>
              <p>
                Once again, "expectations" were to be kept low. This time however, Ken had secretly enlisted the
                help of his friends, Tim and Landon, to help plan a memorable engagement (also shout out to Kim too!).
                The stage was set, the plans were laid, and the expectations were being kept low.
              </p>
              <p>
                The couple once again went to the Country Hills Kinjo location for a nice dinner. They stuffed
                themselves full of sushi and were about to go home. This is when phase 1 of Ken's plan went into
                action. Ken told Jas that Landon and Kim were having some folks over for a kind of "end of summer"
                party, and that even though it was their anniversary they should go. Jas agreed and they drove back
                to Airdrie.
              </p>
              <p>
                Once they arrived home, Ken ensured Jas that she didn't need to go inside and that they would just pop
                in to say "hello". They walked the five or so houses to Landon and Kim's, and entered the backyard.
                "Somebody to Love" by Queen was playing over the speakers, and the backyard was decked out in candlelight.
                Jas knew something was up immediately, but Ken attempted to deflect as he fished the ring out of his pocket.
              </p>
              <p>
                The deflection might have been <i>unsuccessful</i>, but the proposal was. Over two years later, in the place they first
                met, on their anniversary, Ken ask Jas to marry him. Jas said yes.
              </p>
              <p>
                Remember those coffee cups Jas bought when she first said, "I love you"? Those were there too.
                They read "I love you to the mountains and back". "She loves you much further than that," says Jas,
                "He loves you more than pizza, and all the way to the moon," Ken replies.
              </p>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
        <p className='span-12 center rsvp-reminder'>Remember to RSVP so we can slap August 4th, 2023 into this story.</p>
      </div>
    </>
  );
}

export default HowWeMet;
