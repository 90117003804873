import './TextInput.css';
import { useRef } from "react";

interface TextInputProps {
  labelValue: string;
  placeholder: string;
  id: string;
  value?: string | number | readonly string[]
  maxLength?: number;
  required?: boolean;
  onChange?: (value: string) => void
  inputType?: "text" | "email"
}

function TextInput({
  labelValue,
  placeholder,
  id,
  maxLength,
  value = "",
  required = false,
  onChange = () => { },
  inputType = "text"
}: TextInputProps) {
  const ref = useRef<HTMLInputElement>(null);
  return (
    <div className="TextInput-container">
      <div>
        <label htmlFor={id}>{labelValue}</label>
      </div>
      <div>
        <input value={value} maxLength={maxLength} id={id} ref={ref} placeholder={placeholder} type={inputType} required={required} onChange={(val) => onChange(val.currentTarget.value)} />
      </div>
    </div>
  );
}

export default TextInput;
