import './NavMenu.css';
import { NavLink } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { throttle } from 'lodash';

const shrinkPoint = 91;

function NavMenu() {
  const [shrunk, setShrunk] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll)
    return (() => {
      window.removeEventListener('scroll', throttledScroll)
    })
  }, []);

  const throttledScroll = useMemo(() => throttle(scrollListener, 100, { leading: true, trailing: true })
    , []);

  function scrollListener() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const scrolled =
      document.documentElement.scrollTop;

    if (scrolled >= shrinkPoint) {
      setShrunk(true);
    } else if (scrolled === 0) {
      setShrunk(false);
    }
  }

  return (
    <div className="NavMenu">
      <nav className="full-bleed">
        <ul>
          <span></span>
          <li><NavLink to="/">Details</NavLink></li>
          <li><NavLink to="how-we-met">How we met</NavLink></li>
          <li className={"NavMenu-title center desktop " + (shrunk ? "small" : "")}>
            <h1>Jasmyne &amp; McKenzie</h1>
          </li>
          <li><NavLink to="photos">Photos</NavLink></li>
          <li><NavLink to="rsvp">RSVP</NavLink></li>
          <span></span>
        </ul>
        <div className={"NavMenu-title center mobile " + (shrunk ? "small" : "")}>
          <h1>
            Jasmyne &amp; McKenzie
          </h1>
        </div>
      </nav>
    </div>
  );
}

export default NavMenu;
