import {
  Link
} from 'react-router-dom';
import './Home.css';
import splash from '../../assets/photos/castle-gates.jpg';
import Details from '../../Details/Details';


function Home() {
  const daysToGo = calculateDays();
  const dayText = daysToGo > 1 ? "Days" : "Day";

  function calculateDays(): number {
    //month starts at zero
    const milliseconds = new Date(2023, 7, 4).valueOf() - new Date().valueOf();
    return Math.round(milliseconds / 86_400_000);
  }

  return (
    <>
      <div className='Home-wedding-details center'>
        <p>August 4th, 2023 • Akumal, Mexico</p>
      </div>

      <div className="full-bleed Home-splash">
        <img className="splash" src={splash} alt="Landon and Kim's backyard" />
        <a id="lol-butts" href="https://autobear.ca/" target={"_blank"} tabIndex={-1}></a>
        <div>
          <div>
            <p>{daysToGo} {dayText} to Go!</p>
            <Link to="rsvp" className="App-button">RSVP</Link>
          </div>
        </div>
      </div>
      <hr />
      <h2 className='center'>Details</h2>
      <Details />
    </>
  );
}

export default Home;
