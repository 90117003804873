import Gallery, { PhotoClickHandler } from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from './Photo';
import { useCallback, useState } from "react";

function Photos() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox: PhotoClickHandler = useCallback((_event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <>
      <h2 className="center" style={{ marginBottom: '1rem' }}>Photos</h2>
      <div className="full-bleed center">
        <Gallery photos={photos} onClick={openLightbox} margin={8} />
      </div>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
}

export default Photos;
