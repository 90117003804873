import './SelectInput.css';
import { useRef } from "react";

interface SelectInputProps {
  labelValue: string;
  placeholder: string;
  id: string;
  value?: string | number | readonly string[]
  values?: OptionValue[];
  onChange?: (value: string) => void;
}

export interface OptionValue {
  value: any;
  display: number | string;
  selected?: boolean;
}

function SelectInput({
  labelValue,
  placeholder,
  id,
  values = [],
  value,
  onChange = (value: any) => { },
}: SelectInputProps) {
  const ref = useRef<HTMLSelectElement>(null);
  return (
    <div className="SelectInput-container">
      <div>
        <label htmlFor={id}>{labelValue}</label>
      </div>
      <div>
        <select value={value} id={id} ref={ref} placeholder={placeholder} required onChange={(val) => onChange(val.currentTarget.value)} >
          <option value="" selected={values.filter(v => v.value === value).length === 0 ? true : false} disabled>{placeholder}</option>
          {values.map(
            v => <option value={v.value} key={`${id}-${v.value}`}>{v.display}</option>
          )}
        </select>
      </div>
    </div>
  );
}

export default SelectInput;
