import photo1 from '../../assets/photos/battle-on-the-big-bridge.jpg';
import photo2 from '../../assets/photos/september-21.jpg';
import photo3 from '../../assets/photos/big-ol-hug.jpg';
import photo4 from '../../assets/photos/big-smile.jpg';
import photo5 from '../../assets/photos/blurry-leafs.jpg';
import photo6 from '../../assets/photos/bristly-beard-kisses.jpg';
import photo7 from '../../assets/photos/castle-gates.jpg';
import photo8 from '../../assets/photos/happy-puppy.jpg';
import photo9 from '../../assets/photos/jam-session.jpg';
import photo10 from '../../assets/photos/laughing-in-the-yard.jpg';
import photo11 from '../../assets/photos/nose-smoosh.jpg';
import photo12 from '../../assets/photos/one-ring-to-rule-them-all.jpg';
import photo13 from '../../assets/photos/tequilla-mocking-bird.jpg';
import photo14 from '../../assets/photos/to-the-mountains.jpg';
import photo15 from '../../assets/photos/whats-in-the-box.jpg';
import photo16 from '../../assets/photos/battle-on-the-big-bridge-pt2.jpg';
import photo17 from '../../assets/photos/celebration.jpg';
import photo18 from '../../assets/photos/landon-kim-backyard.jpg';
import photo19 from '../../assets/photos/very-happy.jpg';
import photo20 from '../../assets/photos/she-said-yes.jpg';


import { PhotoProps } from 'react-photo-gallery';

export const photos: Array<PhotoProps<any>> = [
  {
    alt: 'Battle on the big bridge',
    src: photo1,
    width: 1280,
    height: 853
  },
  {
    alt: 'Our engagement night',
    src: photo2,
    width: 1549,
    height: 871
  },
  {
    alt: 'A big ol\' hug',
    src: photo3,
    width: 1280,
    height: 853
  },
  {
    alt: 'Nose smoosh',
    src: photo11,
    width: 853,
    height: 1280
  },
  {
    alt: 'Big smile',
    src: photo4,
    width: 1280,
    height: 853
  },
  {
    alt: 'A hug by the ponds',
    src: photo5,
    width: 1280,
    height: 853
  },
  {
    alt: 'Bristly beard kisses',
    src: photo6,
    width: 1280,
    height: 853
  },
  {
    alt: 'By the castle gates',
    src: photo7,
    width: 1280,
    height: 853
  },
  {
    alt: 'One happy puppy',
    src: photo8,
    width: 1280,
    height: 853
  },
  {
    alt: 'Jamming out',
    src: photo9,
    width: 1280,
    height: 853
  },
  {
    alt: 'Very happy',
    src: photo19,
    width: 3024,
    height: 4032
  },
  {
    alt: 'She said yes',
    src: photo20,
    width: 3264,
    height: 2142
  },
  {
    alt: 'Laughing in the yard ',
    src: photo10,
    width: 853,
    height: 1280
  },
  {
    alt: 'Landon and Kim\'s beautiful backyard',
    src: photo18,
    width: 2048,
    height: 1536
  },
  {
    alt: 'Ring in the year with Lego',
    src: photo12,
    width: 1280,
    height: 853
  },
  {
    alt: 'Tequilla mocking bird',
    src: photo13,
    width: 1280,
    height: 853
  },
  {
    alt: 'A big celebration',
    src: photo17,
    width: 1536,
    height: 2048
  },
  {
    alt: 'Love you to the mountains and back',
    src: photo14,
    width: 1280,
    height: 853
  },
  {
    alt: 'What is in this mug?',
    src: photo15,
    width: 1280,
    height: 853
  },
  {
    alt: 'Battle on the big bridge pt2',
    src: photo16,
    width: 853,
    height: 1280
  },
]
