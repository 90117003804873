import TextInput from "../input-components/TextInput";
import SelectInput, { OptionValue } from "../input-components/SelectInput";
import { useEffect, useMemo, useState } from "react";
import { RsvpData } from "../RsvpState";
import { set } from "lodash";

function Thanks({
  data,
}: {
  data: RsvpData,
}) {
  return (
    <>
      <h2 className="center">Thanks {data.names.length && data.names.reduce((pv, cv) => pv ? `${pv} & ${cv}` : pv)}!</h2>
      <div className="center" style={{ fontSize: '156px' }}>🎉</div>
      <div className="grid Rsvp-step">
        {
          !data.coming &&
          <p className="span-12 Rsvp-thanks">
            No worries that you can't make it! We'll catch you at our tornaboda (party back in Canada, details to follow)!
          </p>

        }
        {
          data.coming &&
          <p className="span-12 Rsvp-thanks">
            Your RSVP has been saved. If you want to make changes just re-enter your RSVP code.
            If you plan on using our travel agent, please reach out to our travel agent Bridget
            at <a href="mailto:bmacqueen@maritimetravel.ca">bmacqueen@maritimetravel.ca</a>
          </p>

        }
      </div>
    </>
  );
}

export default Thanks;
