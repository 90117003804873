import './Rsvp.css';
import { useReducer } from "react";
import { collection, getFirestore, serverTimestamp, query, doc, where, getDocs, setDoc } from "firebase/firestore";
import { initialState, RsvpData, RsvpFormState, rsvpReducer } from "./RsvpState";
import FindRsvpCode from "./RsvpSteps/FindRsvpCode";
import SubmitRsvpData from "./RsvpSteps/SubmitRsvpData";
import Thanks from "./RsvpSteps/Thanks";

function Rsvp() {
  const [state, dispatch] = useReducer(rsvpReducer, initialState);
  const searchForRsvp = async (rsvpCode: string) => {
    let error = "";
    try {
      const db = getFirestore();
      const rsvpCollection = collection(db, "rsvp");
      const q = query(rsvpCollection, where("rsvpCode", "==", rsvpCode.toUpperCase()));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        error = "Unable to find your RSVP, please double check your RSVP code.";
      } else {
        const localState = JSON.parse(localStorage.getItem(rsvpCode.toUpperCase()) ?? "{}");
        const result =
          {
            ...querySnapshot.docs[0].data(),
            ...localState
          } as RsvpData;

        document.documentElement.scrollTo(0, 0);
        dispatch({ type: 'foundRsvp', payload: { rsvpId: querySnapshot.docs[0].id, data: result } })
        return;
      }

    } catch (e) {
      console.error(e);
      error = "Unable to fetch RSVP, please try again.";
    }

    if (error) {
      dispatch({ type: 'error', payload: error });
      return;
    }
  }

  const submitRsvp = async (data: RsvpData) => {
    let error = "";
    try {
      const db = getFirestore();
      const firestoreData = {
        coming: data.coming ?? false,
        travelAgent: data.travelAgent ?? false,
        names: data.names ?? [],
        email: data.email ?? "",
        song: data.song ?? "",
        dietaryRestrictions: data.dietaryRestrictions ?? "",
        numberComing: data.coming ? data.numberComing ?? 0 : 0,
        lastModified: serverTimestamp()
      };
      if (data.coming) {
        localStorage.setItem(data.rsvpCode, JSON.stringify(firestoreData));
      } else {
        localStorage.removeItem(data.rsvpCode);
      }

      const rsvpCollection = collection(db, "rsvpResponse");
      const docRef = doc(rsvpCollection, data.rsvpCode);
      await setDoc(docRef, firestoreData);
      document.documentElement.scrollTo(0, 0);
      dispatch({ type: 'submittedRsvp', payload: data });
      return;
    } catch (e) {
      console.error(e);
      error = "Unable to save your RSVP, please try again.";
    }

    if (error) {
      dispatch({ type: 'error', payload: error });
      return;
    }
  }

  return (
    <>
      {state.formState === RsvpFormState.unstarted &&
        <FindRsvpCode onSubmit={searchForRsvp} error={state.error} />
      }
      {state.formState === RsvpFormState.rsvpFound &&
        <SubmitRsvpData data={state.data} onSubmit={submitRsvp} error={state.error} />
      }
      {state.formState === RsvpFormState.rsvpSubmitted &&
        <Thanks data={state.data} />
      }
    </>
  );
}

export default Rsvp;
