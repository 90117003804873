import TextInput from "../input-components/TextInput";
import SelectInput from "../input-components/SelectInput";
import { useState } from "react";

function FindRsvpCode({
  onSubmit,
  error,
}: {
  onSubmit: (rsvpCode: string) => void,
  error?: string
}) {
  const [rsvpCode, setRsvpCode] = useState('');
  return (
    <>
      <h2 className="center">Coming To Our Wedding?</h2>
      <div className="grid Rsvp-step">
        <p className="span-12">A personalized RSVP code was provided with your invite. Please enter it below to continue.</p>
        <p className="span-12"><b>Please RSVP by March 31st, 2023.</b></p>
        <form className="span-12" onSubmit={(evt) => { evt.preventDefault(); onSubmit(rsvpCode) }}>
          <TextInput id="rsvp-code"
            value={rsvpCode}
            required
            placeholder="RSVP Code"
            labelValue="Enter your RSVP Code"
            maxLength={4}
            onChange={setRsvpCode} />
          <hr />
          {error && <p className="span-12 Rsvp-error">{error}</p>}
          <div className="Rsvp-submit-row">
            <button className="App-button" type="submit">Continue</button>
          </div>
        </form>
      </div>
    </>
  );
}

export default FindRsvpCode;
