import notFound from '../../assets/not-found.jpg';

function NotFound() {

  return (
    <>
      <h1 className='center'>Whoops - Page Not Found!</h1>
      <img className='not-good-with-computer' src={notFound} alt="how did this get here I am not good with computer." />
    </>
  );
}

export default NotFound;
