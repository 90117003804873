export enum RsvpFormState {
  unstarted,
  rsvpFound,
  rsvpSubmitted,
}

export interface RsvpData {
  rsvpCode: string;
  coming: boolean;
  travelAgent: boolean;
  names: string[];
  email: string;
  song: string;
  dietaryRestrictions: string;
  numberInvited: number;
  numberComing?: number;
}

export interface RsvpState {
  formState: RsvpFormState;
  rsvpId: string;
  data: RsvpData;
  error?: string
}

export const initialState: RsvpState = {
  formState: RsvpFormState.unstarted,
  rsvpId: '',
  data: {
    rsvpCode: '',
    coming: true,
    travelAgent: true,
    names: [],
    email: '',
    song: '',
    dietaryRestrictions: '',
    numberInvited: 0,
  },
  error: '',
};

export type ACTIONTYPE =
  { type: "error"; payload: string }
  | { type: "reset"; payload: null }
  | { type: "foundRsvp"; payload: { rsvpId: string, data: RsvpData } }
  | { type: "submittedRsvp"; payload: RsvpData };

export function rsvpReducer(state: RsvpState, action: ACTIONTYPE): RsvpState {
  switch (action.type) {
    case 'error':
      return {
        ...state,
        error: action.payload
      }
    case 'reset':
      return {
        ...initialState
      }
    case 'foundRsvp':
      return {
        ...state,
        formState: RsvpFormState.rsvpFound,
        rsvpId: action.payload.rsvpId,
        data: action.payload.data,
        error: ''
      };
    case 'submittedRsvp':
      return {
        ...state,
        formState: RsvpFormState.rsvpSubmitted,
        data: action.payload,
        error: ''
      };
    default:
      throw new Error();
  }
}


