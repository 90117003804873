import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink
} from "react-router-dom";
import './App.css';
import NavMenu from './layout/NavMenu';
import Home from './views/home/Home';
import HowWeMet from './views/how-we-met/HowWeMet';
import NotFound from './views/not-found/NotFound';
import Photos from './views/photos/Photos';
import Rsvp from './views/rsvp/Rsvp';

function App() {
  const year = new Date().getFullYear();
  return (
    <Router>
      <div className="App">
        <NavMenu />
        <main>
          <hr />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="rsvp" element={<Rsvp />} />
            <Route path="how-we-met" element={<HowWeMet />} />
            <Route path="photos" element={<Photos />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <footer>
          <hr />
          Love Me Tendies 🐤 - Jazz &amp; Ken's wedding site &copy; {year}
        </footer>
      </div>
    </Router>
  );
}

export default App;
