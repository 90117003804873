// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInAnonymously } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB4GG2gSlTkJsfrV_7IW1lqCOzQi8zGz44",
  authDomain: "wedding-c58dd.firebaseapp.com",
  projectId: "wedding-c58dd",
  storageBucket: "wedding-c58dd.appspot.com",
  messagingSenderId: "626519441662",
  appId: "1:626519441662:web:527e61623bd956353925af",

};

export const initializeFirebase = () => {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LcFp3whAAAAAHcRHvqZJja9eVoXHSuXcoL3I4Vc'),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });

  const db = getFirestore(app);


  const auth = getAuth();
  signInAnonymously(auth)
    .then(() => {
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
    });
}
