import './Details.css';

function Details() {
  return <>
    <div className="Detail-detail-container Detail-margin-top">
      <div>Who:</div>
      <div><p>Jasmyne Hazelwanter &amp; McKenzie Long.</p></div>
    </div><div className="Detail-detail-container">
      <div>Where:</div>
      <div>
        <p>At the Akumal Beach and Wellness Resort. Check out the <a href="http://www.akumalbayresort.com/" target={"_blank"}>resort's website here, </a>
          or check out <a href="https://www.facebook.com/AKUMALBR/" target={"_blank"}>their Facebook page.</a></p>
      </div>
    </div><div className="Detail-detail-container">
      <div>When:</div>
      <div>
        <p>Specifically, August 4th, 2023 at 4pm. That being said, we will be booking a week long stay for the week of the 1st.
          If you choose to attend we will have the option to book as a group through a travel agent (details to follow).</p>
        <p><b>Please RSVP by March 31st, 2023.</b></p>
      </div>
    </div>
  </>
}

export default Details;
